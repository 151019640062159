import React from "react"
import * as styles from "../../styles/index.module.css"
import * as stylesComunicado from "../../styles/comunicados.module.css"

import { styled } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"

import vallesMar from "../../images/valles_mar.jpg"
import entradaVallesMar from "./../../../static/entrada_valles_mar.png"
import multisensor from "./../../../static/multisensor-hero.jpg"
import descargarPdf from "./../../../static/descargar-pdf.png"
import LogoApp from "./../../../static/AppGA.png"
import btnGP from "./../../../static/btn-google-play.png"
import btnAS from "./../../../static/btn-app-store.png"
import dispositivos from "./../../../static/dispositivos_app.png"

import { Link } from "gatsby"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}))

const AvisoVallesDelMar = () => (
    <Layout>
        <Helmet>
            <title>Aries Online - Valles del Mar</title>
        </Helmet>
        <section id="ComunicadoVallesDelMar" className={stylesComunicado.comunicado}>
            <Box sx={{ flexGrow: 1 }}>
                {/* {console.log(items)} */}
                {/* {items.map((item, index) => ( */}

                <Grid key={1} container className={`${styles.gridAP}`}>
                    {/* {item.map(item => (
             */}
                    <Grid key={"item-title"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleFraccionamiento} `}>
                            Valles del Mar
                        </h1>
                        <h1 className={`${stylesComunicado.titleComunicado}`} style={{textDecoration: "none"}}>
                            Atento Aviso Informativo
                        </h1>
                        <h2 className={stylesComunicado.fecha}>Febrero 2023</h2>
                    </Grid>
                    <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item1"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Nos complace mucho saludarle, deseándole salud y bienestar para usted y sus seres queridos, de igual manera nos permitimos informarle que de acuerdo con nuestra certificación de calidad
                                    ISO 9001-2015 de servicio al cliente en Grupo Aries, le hemos asignado un ejecutivo de atención personalizada mediante el Lic. Rodolfo Ramirez y su equipo de trabajo de lunes a sábado
                                    de 10:00 am a 7:00 pm horario Ciudad de México en los teléfonos <a className={stylesComunicado.link} href="tel:+18556691306">(185) 566 91 306</a> desde USA,
                                    y <a className={stylesComunicado.link} href="tel:+528008003938">(800) 800 39 38</a> desde México, o bien nos puede escribir a <a className={stylesComunicado.link}href="mailto:sac@mipatrimonio.com">sac@mipatrimonio.com</a> para atender cualquier duda, inquietud o aclaración sobre su inversión en Residencial Valles del Mar.

                                </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid key={"item-2"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item2"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Respecto a los pagos de su inversión, cuotas de mantenimiento, servicios de construcción, escrituración y/o cualquiera de nuestros productos, le invitamos a que los realice de una manera rápida, sencilla y segura a través de nuestros medios electrónicos ya que por seguridad y en cumplimiento con lo dispuesto por la Ley Federal de Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita (LFPIORPI) en Prevención de Lavado de Dinero y Financiamiento al Terrorismo (PLD/FT) en México, le informamos que: a partir del 1º de abril 2023 nuestras oficinas y/o cajas de Grupo Aries no recibirán dinero en efectivo.
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"TituloPagos"} item lg={10} md={10} sm={10} xs={11}>
                    <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Formas de Pago
                        </h1></Grid>
                    <Grid key={"FormatoCreditoDebito"} item lg={10} md={10} sm={10} xs={11} className={stylesComunicado.PDF} >
                        <Grid item lg={4} md={4} sm={10} xs={11} className={stylesComunicado.divPDF}>
                            <Link target={"_blank"} to="https://bit.ly/CargoMEX2023" className={styles.gridItemAP}>
                                <img src={descargarPdf} />
                            </Link>
                            <Link target={"_blank"}  className={styles.textLeftAP} to="https://bit.ly/CargoMEX2023">
                                Cargo automático <br/>residentes en México
                            </Link>
                            <p>
                                Formato tarjeta <br/>de crédito o débito
                            </p>
                        </Grid>
                        <Grid item lg={4} md={4} sm={10} xs={11} className={stylesComunicado.divPDF}>
                            <Link target={"_blank"} to="https://bit.ly/CargoUSA2023" className={styles.gridItemAP}>
                                <img src={descargarPdf} />
                            </Link>
                            <Link target={"_blank"}  className={styles.textLeftAP} to="https://bit.ly/CargoUSA2023">
                                Cargo automático <br/>residentes en USA
                            </Link>
                            <p>
                                Formato tarjeta <br/>de crédito o débito
                            </p>
                        </Grid>
                        <Grid item lg={4} md={4} sm={10} xs={11} className={stylesComunicado.divPDF} >
                            <Link target={"_blank"} to="https://bit.ly/usaACH"  className={styles.gridItemAP}>
                                <img src={descargarPdf} />
                            </Link>
                            <Link target={"_blank"}  className={styles.textLeftAP} to="https://bit.ly/usaACH">
                                Cargo automático <br/>residentes en USA
                            </Link>
                            <p>
                                Formato cuenta <br/>de cheques (ACH)
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={11} className={stylesComunicado.divApp}>
                        <Item key={"itemLogoApp"} className={`${styles.gridItemAP} ${stylesComunicado.divLogoApp}`} >
                            <Link target={"_blank"} to={"https://payments.mipatrimonio.com/"}>
                                <img src={LogoApp} className={stylesComunicado.imgLogoApp} />
                            </Link>
                            <p style={{ textAlign: "center" }}>
                                App Grupo Aries
                            </p>
                        </Item>
                        <Item className={` ${styles.gridItemAP} ${stylesComunicado.divBtnsApp}`} >
                            <Link target={"_blank"} to={"https://play.google.com/store/apps/details?id=io.aries.app"}>
                                <img src={btnGP} className={stylesComunicado.imgBtn} />
                            </Link>
                            <Link target={"_blank"} to={"https://apps.apple.com/es/app/app-grupo-aries/id1592405228"}>
                                <img src={btnAS} className={stylesComunicado.imgBtn} style={{ margin: "0px" }} />
                            </Link>
                        </Item>
                        <Item key={"itemLogoApp"} className={`${styles.gridItemAP} ${stylesComunicado.divLogoApp} ${stylesComunicado.imgDispositivos}`} >
                            <Link target={"_blank"} to={"https://payments.mipatrimonio.com/"}>
                                <img src={dispositivos} className={stylesComunicado.imgDispositivos} />
                            </Link>
                        </Item>
                    </Grid>
                 
                    <Grid item lg={5} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Avances de Urbanización
                        </h1>
                        <Item className={styles.gridItemAP}>

                            <div className={styles.itemDivTextAP}>
                                <p className={styles.textLeftAP}>
                                    Con relación a los <b><i>avances en nuestras obras de urbanización en Valles del Mar</i></b> nos alegra informarle que nos encontramos en su etapa final; trabajando en el proyecto del tanque de almacenamiento de agua potable para instalar el equipo de bombeo para su abastecimiento por parte de la ciudad conectado al acueducto herrera la misión en Rosarito B.C., ya que contamos con nuestras factibilidades de agua favorables y aprobadas con sus correspondientes derechos pagados a la Comisión Estatal de Servicios Públicos (CESPT) así como exitosamente incorporadas sus redes de electrificación, alumbrado público, agua potable, red de drenaje sanitario por cuenta del desarrollador, para de ahí terminar con su casa club con albercas climatizadas, áreas deportivas y comunes (Aries Vacation Club) actualmente en ejecución asi cómo los pavimentos de su última sección que pretendemos finalizar en este mismo 2023.
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"itemImgVallesdelMAr"} item lg={5} md={10} sm={10} xs={11}>
                        <Item key={"itemValles"} className={styles.gridItemAP}>
                            <img src={vallesMar} style={{ paddingInline: "15px" }} />
                        </Item>
                    </Grid>

                    <Grid item lg={12} className={`${stylesComunicado.mdFlexColumnReverse} ${stylesComunicado.divImageReverse}`} >
                        <Grid item lg={5} md={10} sm={10} xs={11}>
                            <Item className={styles.gridItemAP}>
                                <img src={entradaVallesMar} style={{ paddingInline: "15px" }} />
                            </Item>
                        </Grid>

                        <Grid key={"item-3"} item lg={5} md={10} sm={10} xs={11}>
                            <Item key={"item3"} className={styles.gridItemAP}>
                                <div className={styles.itemDivTextAP}>
                                    <p>
                                        Así mismo, también hemos instalado su respectiva línea de impulsión o conducción de agua potable lo largo de todo el trayecto a Valles del Mar que adicionalmente como Empresa Socialmente Responsable(ESR) nos dimos la tarea de reparar y arreglar desde la carretera libre con una base hidráulica y un riego de impregnación asfáltica para hacerlo más transitable aun en época de lluvias, con el único fin de que usted acceda a Valles del Mar en su automóvil de manera más fácil, rápida y segura a pesar de que esto último; nunca formo parte de nuestras obligaciones suscritas con usted, además de estar fuera de nuestros polígonos y/o superficies de Valles del Mar.
                                    </p>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>

                    <Grid key={"item4"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Cuota de Mantenimiento
                        </h1>
                        <Item key={"item4"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    El oportuno pago de su contractual cuota de mantenimiento vigente a nuestra empresa profesional de mantenimiento Century 21 Aries, tienen como propósito mantener este acceso y a nuestro residencial con sus servicios, amenidades y seguridad en condiciones óptimas, que indiscutiblemente incrementan el valor de su inversión con la más alta plusvalía y consolidan su patrimonio con la mejor calidad de vida objeto de nuestra misión institucional en Grupo Aries.
                                </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid key={"item-5"} item lg={5} md={10} sm={10} xs={11}>
                        <Item key={"item5"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Porque su seguridad y bienestar es nuestra prioridad; nos encontramos reforzando agresivamente su seguridad y vigilancia las 24/7 con patrullas y elementos de la secretaria de seguridad pública, policías armados, guardias mejor adiestrados, cámaras de video. Por lo que <b>a partir del 1º de febrero deberá pagar una cuota mensual de mantenimiento por un valor de 50 dólares al mes y una vez concluidas las obras de urbanización, su contractual cuota de mantenimiento se ajustará a un valor final de 130 dólares mensuales</b> con eventuales incrementos en lo sucesivo con un tope máximo del 3% anual de la inflación de USA dependiendo de las necesidades de mantenimiento o mejoras al residencial como se establece en sus obligaciones descritas en su contrato.
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"item-6"} item lg={5} md={10} sm={10} xs={11}>
                        <Item key={"item6"} className={styles.gridItemAP}>
                            <img src={multisensor} style={{ paddingInline: "15px", width: "100%" }} />
                        </Item>
                    </Grid>
                    <Grid key={"item-7"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item7"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    No obstante, lo anterior es importante que, una vez construida su residencia, cuente con un seguro de menaje de casa, un servicio de alarma y cámaras de video como en cualquier residencial del mundo. Hoy en día estos productos y servicios son muy económicos y fáciles de contratar, porque al final la seguridad es tarea de todos.
                                </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid key={"item-8"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item8"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Definitivamente hoy es el momento idóneo para iniciar la construcción de su residencia con atractivas facilidades de pago por medio de nuestro equipo constructor, contamos con varios modelos con la mejor arquitectura y distribución de espacios, calidad, precio y garantías, comuníquese con nosotros para hacerle llegar valiosa información para ayudarle a construir la casa de sus sueños.
                                </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid key={"item4"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Entrega Física (Acta de Identificación de Inmueble)
                        </h1>
                        <Item key={"item4"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Por su seguridad y con el objeto de garantizarle éxito en su inversión hemos modificado el <b>proceso de la <i>entrega física</i></b> de su inmueble, ya que lamentablemente tenemos documentado en nuestros registros históricos durante los últimos 20 años testimonios, denuncias y graves incidencias de terceros como es el caso de contratistas y/o constructores ajenos a nuestra organización entre otros, que terminan recurrentemente defraudando y dañando el patrimonio de nuestros clientes por lo que una vez que aprobemos y/o iniciemos la construcción de su residencia llevaremos a cabo una <b>acta de identificación de su manzana y lote con su respectiva superficie para inicio de obras de edificación en lo sucesivo <i>“acta de identificación de inmueble”, en lugar de la entrega física</i></b>; que exitosamente ha venido funcionando con gran eficiencia, satisfacción y tranquilidad de nuestros clientes y/o residentes derivado de nuestra mejora constante y continua en Grupo Aries con la ventaja que se puede llevar a cabo en el momento que usted lo decida a partir del tiempo transcurrido contractualmente, se encuentre al corriente con todas sus obligaciones y no tenga adeudo alguno.
                                </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid key={"item4"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Promoción hasta 31 de marzo 2023
                        </h1>
                        <Item key={"item4"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                <b>Para estos efectos le damos la excelente noticia de que hasta el 31 de marzo, si califica; podríamos condonar hasta un 100% de su interés moratorio con el fin de que se ponga al corriente con el pago de sus mensualidades pendientes y/o contractuales cuotas de mantenimiento vigentes y/o cualquier otro adeudo en una sola exhibición y en lo sucesivo realice el pago de sus obligaciones con cargo domiciliado y recurrente a su cuenta bancaria o mediante nuestra APP Grupo Aries,</b> porque además tendrá la oportunidad de recibir boletos para participar en nuestro <b>Sorteo Aries Adventures & Experience 2023</b> para ganar un primer premio de un Racer Marca Kawasaki  y/o un segundo premio de una Motocicleta marca Kawasaki actualmente en exhibición en Aries Vacation Club Puntazul Residencial. Permiso SEGOB 20220260PS09.
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    {/* <Grid key={"item4"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Formas de Pago Valles del Mar
                        </h1>
                        <Item key={"item4"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p className={styles.textLeftAP}>
                                    Método ACH, Cargo Recurrente, Transferencia, APP, web….
                                </p>
                            </div>
                        </Item>
                    </Grid> */}


                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Carta Finiquito
                        </h1>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Si usted ya liquido su inmueble, no tiene adeudos y se encuentra al corriente con sus contractuales cuotas de mantenimiento vigentes, <b>se le emitirá la carta finiquito en el entendido que este documento NO le traslada el dominio y/o NO lo convierte en un legítimo propietario del inmueble,</b> así como tampoco lo libera o exime del pago contractual de otras obligaciones como sus cuotas de mantenimiento vigentes a la empresa encargada de brindar mantenimiento profesional y seguridad preventiva entre otros servicios, amenidades y beneficios al residencial y sus residentes.
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Cesión de Derechos
                        </h1>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    En virtud a lo anterior; en el caso de que pretenda <b><i>vender</i></b> o hacer una <b><i>cesión de derechos</i></b> aun sin haber liquidado su inversión como cedente debe estar al corriente con todas sus obligaciones incluyendo las cuotas de mantenimiento y su cesionario deberá contratar y pagar por adelantado nuestro servicio de construcción y escrituración asi como realizar por adelantado el pago en una sola exhibición de un año de sus respectivas cuotas de mantenimiento vigentes al momento de realizar la cesión, <b><i>previa autorización expresa y por escrito por parte del desarrollador o Grupo Aries.</i></b>
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Escrituración del inmueble
                        </h1>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Por lo que, si usted ya liquido su inmueble, cuenta con carta finiquito y se encuentra al corriente con sus contractuales cuotas de mantenimiento vigentes; debe contactar en breve a su ejecutivo de Servicio al Cliente para asesorarle para que obtenga su <b><i>título de propiedad, escritura pública o fideicomiso</i></b> según su nacionalidad, debidamente inscrita en el Registro Público de la Propiedad y del Comercio y se convierta legalmente en <b>dueño de su privilegiada ubicación</b> en Valles del Mar.
                                </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Impuesto Predial
                        </h1>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Una vez escriturado su inmueble y con fundamento en los artículos 35, 46 y 47 de la Ley de Catastro Inmobiliario de Baja California, así como, con lo dispuesto por los relativos y aplicables 49, 82 y 83 del Reglamento de Catastro Inmobiliario para el Municipio de Playas de Rosarito vigente, para todos los efectos legales a que haya lugar procederemos a cambiar la boleta del <b><i>Impuesto Predial</i></b> a su nombre como propietario poseedor, o bien; una vez que iniciemos la construcción de su residencia pero solo en el caso de que nuestras autoridades asi lo requieran.
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Reventas
                        </h1>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Porque más que su dinero, nuestro interés es su plena satisfacción; Grupo Aries le ofrece la posibilidad de que recupere su inversión “hasta” con un 10% de plusvalía a través de nuestras <b><i>Reventas</i></b> dependiendo su fecha de compra, precio, ubicación, superficie y las condiciones económicas del mercado inmobiliario o Real Estate con la ventaja de que ahorra todo el proceso de escrituración evitando asi honorarios de abogados y sus respectivos tramites, comisiones a vendedores, cálculos erróneos de impuestos, y/o que sea sujeto de fraude, abusos y engaños.
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Si usted no se siente confortable con su adquisición o tiene interés en recuperar el valor de su inversión contacte a su ejecutivo de servicio al cliente o escribanos a <a className={stylesComunicado.link} href="mailto:sac@mipatrimonio.com">sac@mipatrimonio.com</a> para valorar y determinar su situación ya que este beneficio NO es una obligación contractual suscrita con usted, pero si una posibilidad dependiendo su caso e historial que debe mantener al corriente en todas y cada una de sus obligaciones contractuales ya que la falta de pago por tres o más mensualidades consecutivas causaran la recisión de su inversión sin devolución alguna.
                                </p>
                            </div>
                        </Item>
                    </Grid>





                    <Grid key={"item-8"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Contáctenos o Visítenos
                        </h1>
                        <Item key={"item8"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Visítenos en el residencial <b>con previa cita en los teléfonos con su ejecutivo de servicio al cliente Lic. Rodolfo Ramirez</b> en Carretera Libre Tijuana a Ensenada km 40.
                                </p>
                                <iframe src={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3340.0194863752185!2d-116.9561947!3d32.2674324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d925525c4bd703%3A0x6c24f2f63dc8066c!2sVALLES%20DEL%20MAR!5e1!3m2!1ses-419!2smx!4v1677031929100!5m2!1ses-419!2smx"}
                                    className={stylesComunicado.iframeMaps} allowFullScreen={false} loading={"lazy"} referrerPolicy={"no-referrer-when-downgrade"}></iframe>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3340.0194863752185!2d-116.9561947!3d32.2674324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d925525c4bd703%3A0x6c24f2f63dc8066c!2sVALLES%20DEL%20MAR!5e1!3m2!1ses-419!2smx!4v1675907923934!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                        </Item>
                    </Grid>

                    <Grid key={"item-9"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item9"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    O desde la comodidad de su hogar
                                </p>
                                <iframe className={stylesComunicado.iframeMaps} allowFullScreen={true} src={"https://ths.li/bMPrQM9"} ></iframe>
                            </div>
                        </Item>
                    </Grid>

                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    O bien escribanos a <a className={stylesComunicado.link} href="mailto:sac@mipatrimonio.com">sac@mipatrimonio.com</a> con sus cometarios. No escriba en las redes sociales porque son administradas por un tercero a Grupo Aries y Valles del Mar solo con fines de promoción y publicidad para incrementar el valor de su inversión y no de servicios al cliente.
                                </p>
                            </div>
                        </Item>
                    </Grid>

                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <h1 className={`${stylesComunicado.titleComunicado}`}>
                            Acerca de Grupo Aries
                        </h1>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Muchas Felicidades por invertir en Grupo Aries, una Empresa Socialmente Responsable (ESR) por séptimo año consecutivo, la primera y única empresa con el distintivo de Socialmente Incluyente en el Estado de Baja California, Socios con Voz y Voto del Consejo de Administración del Centro Mexicano de la Filantropía, El Cemefi; la cuna de las Empresas Socialmente Responsables.
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    La Dimensión Social Empresarial de Grupo Aries inicia fortaleciendo el patrimonio de las familias, y continua con el de sus colaboradores por medio de su certificación Great Place To Work con un primer lugar en 2021 en el sector inmobiliario y de la construcción del ranking nacional, para de ahí contribuir con la reconstrucción de nuestro tejido social apoyando al talento, al deporte, al medio ambiente, la educación, el arte, la cultura, asi como a nuestros niños de escasos recursos y/o con capacidades diferentes porque son el presente y futuro del mundo; a esto es a lo que Grupo Aries le llamamos nuestro círculo virtuoso Ganar Ganar; una empresa donde ganamos todos.
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Grupo Aries es orgullosamente una Empresa del Pacto Mundial México de las Naciones Unidas y entusiastas promotores de los 17 Objetivos de Desarrollo Sostenible (ODS) de la Agenda 2030 de acuerdo con su carta de emprendimiento firmada el 03 de Diciembre 2019 en Viena Austria para la ONU, ganadores del Premio de Etica y Valores por la Confederación de las Cámaras de la Industria de los Estados Unidos Mexicanos por cuarto año consecutivo; Premio Concamin, además de contar con reconocimientos y acreditaciones de talla internacional en el Estado de California, San Francisco CA, New York NY, Miami FL, Sao Paulo Brasil, Paris Francia, Viena Austria figurando dentro de ranking de las 1000 Empresas Mas Importantes en México por la prestigiada industria editorial Mundo Ejecutivo por sexto año consecutivamente destacando a Jorge Ojeda dentro de los 101 empresarios que hacen grande México en 2017.
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Jorge Ojeda, CEO de Grupo Aries ganador del premio de Revelación Empresarial por Gana Ganar en 2019; inició su carrera como ejecutivo de ventas en la casa Editorial Grolier en 1984; cuando tenía 14 años de edad.                                 </p>
                            </div>
                        </Item>
                    </Grid>
                    <Grid key={"item-10"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item10"} className={styles.gridItemAP}>
                            <div className={styles.itemDivTextAP}>
                                <p>
                                    Por su gran experiencia, trayectoria ejecutiva y altruismo ha sido invitado para participar como líder de opinión en los foros de mayor relevancia en México y USA lo que le ha permitido la condecoración del Doctor Honoris Causa en tres ocasiones y ser candidato a gobernador de Baja California en 2021.
                                </p>
                            </div>
                        </Item>
                    </Grid>


                    <Grid key={"item-11"} item lg={10} md={10} sm={10} xs={11}>
                        <Item key={"item11"} className={styles.gridItemAP}>
                            <div className={`${styles.itemDivTextAP} ${styles.lastUpdateTextAP}`} >
                                <p>
                                <b>
                                    Muy Atentamente;<br></br>
                                    Dirección Corporativa de Servicios al Cliente.
                                    </b>
                                </p>
                            </div>
                        </Item>
                    </Grid>
                    {/* ))} */}
                </Grid>
                {/* ))} */}
            </Box>
        </section>
    </Layout>
)

export default AvisoVallesDelMar

